import React from "react";
import { PageNotFoundContainer } from "./style";
import { KeepingSafeImage } from "../../assests";
import MyButton from "../Elements/MyButton";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

const PageNotFoundPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <PageNotFoundContainer>
      <div
        style={{
          backgroundImage: `url(${KeepingSafeImage})`,
          height: "647px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
        className="page-not-found-container"
      >
        <div className="inner-container">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "PAGE_NOT_FOUND.TITLE",
              }),
            }}
          />
          <div
            className="sub-title"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "PAGE_NOT_FOUND.SUB_TITLE",
              }),
            }}
          />
          <MyButton className="action-button" onClick={() => navigate("/")}>
            {intl.formatMessage({
              id: "BUTTON.BACK_TO_HOME_PAGE",
            })}
          </MyButton>
        </div>
        <div className="not-found-number">404</div>
      </div>
    </PageNotFoundContainer>
  );
};

export default PageNotFoundPage;
