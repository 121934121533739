import React from "react";
import { MyButtonContainer } from "./style";

export default function MyButton({
  children,
  className = "",
  onClick = () => {},
  disabled = false,
  type = "",
}) {
  return (
    <MyButtonContainer
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </MyButtonContainer>
  );
}
