import React, { useContext, useEffect } from "react";
import { LayoutPageContainer } from "./style";
import NavBar from "./NavBar/NavBar";
import Footer from "./Footer/Footer";
import { MyAppContext } from "../context/MyAppContext";
import MyNotification from "../components/Elements/MyNotification";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const {
    notification,
    setNotification,
  } = useContext(MyAppContext);

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },[pathname])

  return (
    <LayoutPageContainer
    >
      {notification && (
        <MyNotification
          message={notification?.message ? notification.message : ""}
          table={notification?.table ? notification.table : ""}
          backGroundColor={
            notification?.backGroundColor ? notification.backGroundColor : ""
          }
          icon={notification?.icon ? notification.icon : ""}
          onClick={() => setNotification(null)}
        />
      )}
      <NavBar />
      {children}
      <Footer />
    </LayoutPageContainer>
  );
};

export default Layout;
