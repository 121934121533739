import styled from "styled-components";
import HeroBg from "../../assests/herobg.jpg";
import MobileHeroBg from "../../assests/mobileBg.png";
import { AngleDownLarge } from "../../assests";
import PhoneInput from "react-phone-number-input";

export const HomeWrapper = styled.div`
width: 100%;
height: max-content;
background: url(${HeroBg});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
display: flex;
justify-content: space-between;
/* align-items: center; */
padding: 160px 164px 178px 164px;
gap: 60px;

@media (max-width: 768px) {
    background: url(${MobileHeroBg});
    background-size: cover;
background-position: center;
background-repeat: no-repeat;
padding: 140px 20px 80px 20px;
flex-direction: column;
  }
  @media (max-width: 1000px) {
    background: url(${MobileHeroBg});
    background-size: cover;
background-position: center;
background-repeat: no-repeat;
padding: 140px 20px 80px 20px;
flex-direction: column;
  }


h1{
    width: 50%;
    align-self: center;
    font-size: 56px;
    font-weight: 800;
    font-family: Roboto;
    line-height: 76px;
    color: #fff;

    span{
        color:#C8DCFA;
    }

    @media (max-width: 768px) {
   font-size: 36px;
   width: 100%;
  }
  @media (max-width: 1000px) {
    font-size: 36px;
   width: 100%;
  }



}

.form-container{
    width: 453px;
    height: 540px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap:20px;

    @media (max-width: 768px) {
   width: 100%;
  }
  @media (max-width: 1000px) {
   width: 100%;
  }



.btn{
    height: 40px;
    border-radius: 12px;
    background: #056DFF;
    border: none;
    color: #fff;
    font-size: 14px;
        font-family: human_sans_400;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;

        &:disabled{
            opacity: 0.4;
            pointer-events: none;
        }
}

}


.phone-number{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    label{
        font-size: 11px;
        font-family: human_sans_400;
        line-height: 12px;
    }

}

`

export const StyledPhoneInput = styled(PhoneInput)`
  border-radius: 12px 0 0 12px;
  width: -webkit-fill-available;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  &.PhoneInput {
    border-radius: 12px !important;
    height: 40px;
  }
  .PhoneInputCountry {
    /* border-right: 1px solid #91afd770; */
    padding: 12px;
    margin-right: 0px;

.PhoneInputCountrySelectArrow{
    background-image: url(${AngleDownLarge});
    width: 10px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    border: none;
    transform: none;
}

  }
  input {
    border-radius: 0 12px 12px 0 !important;
    border: solid 1px #9b9b9b;
    font-family: human_sans_400;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #14283C;
    outline: unset !important;
    border: unset !important;
    padding: 10px 0px;
    height: 40px;
    ::placeholder {
      line-height: 24px;
      color: #91afd7;
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
    }
  }
`;