import styled from "styled-components";

export const PageNotFoundContainer = styled.div`
  .page-not-found-container {
    padding-top: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    .inner-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 10;
      .title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
      }
      .sub-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 52px;
        text-align: center;
        letter-spacing: 0.024em;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .action-button {
        width: 316px;
        height: 50px;
        cursor: pointer;
      }
      .app-store-icons {
        margin-top: 56px;
        display: flex;
        align-items: center;
        gap: 20px;
        img {
          height: 40px;
          width: 135px;
          border-radius: 5px;
        }
      }
    }
    .not-found-number {
      font-size: 33rem;
      position: absolute;
      opacity: 0.18;
      z-index: 1;
      color: #ffffff;
    }
  }
`;
