import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { Steps } from "antd";
import {
  CountryDropdown,
  RegionDropdown,
} from "react-country-region-selector";
import { AngleDown } from "../../assests";

export const BecomeResponderFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px 24px;
  .progress-connector-line {
    @media (max-width: 1000px) {
      width: 250px !important;
    }
    @media (max-width: 450px) {
      width: 100px !important;
    }
  }

  .header, .sub-header {
  font-family: "roboto_condensed_700";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #14283c;
  }
.sub-header {
  font-size: 16px;
  font-family: "roboto_condensed_400";
}

  .d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 26px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: start;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: start;
    }
  }
  .phone-number-div {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
    label {
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 11px;
      line-height: 12px;
      color: #5573a0;
    }
  }
  .d-flex-column {
    display: flex;
    flex-direction: column;
    gap: 2px;
    // margin-bottom: 24px;
    flex: 1 1 0;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
    label {
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 11px;
      line-height: 12px;
      color: #5573a0;
    }
  }
  .subscription {
    background-color: #ffa53326;
    border-radius: 30px;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    .subscription-info {
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      color: #14283c;
    }
  }
  .state-zip-containers {
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      width: 100%;
    }
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    @media (max-width: 768px) {
      gap: 10px;
    }
    @media (max-width: 1000px) {
      gap: 10px;
    }
    .cancel-btn {
      font-family: human_sans_700;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      width: 142px;
      @media (max-width: 768px) {
        width: 100px;
        padding: unset;
      }
      @media (max-width: 1000px) {
        width: 100px;
        padding: unset;
      }
    }
    .submit-btn {
      font-family: human_sans_700;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      @media (max-width: 768px) {
        width: 150px;
        padding: unset;
      }
      @media (max-width: 1000px) {
        width: 150px;
        padding: unset;
      }
    }
    .submit-btn-form {
      font-family: human_sans_700;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      @media (max-width: 768px) {
        width: 253px;
        padding: unset;
      }
      @media (max-width: 1000px) {
        width: 253px;
        padding: unset;
      }
    }
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  border-radius: 12px 0 0 12px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  &.PhoneInput {
    border-radius: 12px !important;
  }
  .PhoneInputCountry {
    border-right: 1px solid #91afd770;
    padding: 12px;
  }
  input {
    border-radius: 0 12px 12px 0 !important;
    border: solid 1px #9b9b9b;
    font-family: human_sans_400;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #14283c;
    outline: unset !important;
    border: unset !important;
    padding: 10px 12px;
    ::placeholder {
      line-height: 20px;
      color: #91afd7;
      font-family: human_sans_400;
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const GenderListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .gender-div {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #c8dcfa;
    mix-blend-mode: normal;
    // opacity: 0.5;
    border-radius: 12px;
    font-family: human_sans_400;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // color: #056dff;
    cursor: pointer;
    padding: 7.5px;
    b,
    span {
      margin-top: 5px;
      margin-left: 5px;
    }
  }
`;

export const StyledSteps = styled(Steps)`
  width: 250px;
  margin-bottom: 19px;

  .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-title {
          &:after {
            background-color: #c8dcfa;
          }
        }
      }
      .ant-steps-item-icon {
        .ant-steps-icon {
          font-family: human_sans_700;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
        }
      }
    }
  }
  .ant-steps-item.ant-steps-item-wait {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background-color: #c8dcfa;
        .ant-steps-icon {
          color: #056dff;
          font-family: human_sans_700;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
        }
      }
    }
  }
`;

export const TransportationDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1 1 0;
  width: 165px;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  label {
    font-family: human_sans_400;
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    line-height: 12px;
    color: #5573a0;
  }
  .select-transportation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
    border-radius: 12px;
    padding: 10px 12px;
    position: relative;
    .un-select {
      font-family: human_sans_400;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #91afd7;
    }
    .select {
      font-family: human_sans_400;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #14283c;
    }
    .dropdown-list {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
      border-radius: 12px;
      padding: 10px 12px;
      position: absolute;
      top: 47px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: start;
      font-family: human_sans_400;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #14283c;
      span {
        cursor: pointer;
      }
    }
  }
`;

export const StyledCountryDropDown = styled(CountryDropdown)`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  border-radius: 12px;
  padding: 11px 12px;
  outline: unset !important;
  border: unset !important;
  font-family: human_sans_400;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #14283c;
  width: 340px;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${AngleDown});
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`;

export const StyledStateDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const StyledStateDropDown = styled(RegionDropdown)`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  border-radius: 12px;
  padding: 11px 12px;
  outline: unset !important;
  border: unset !important;
  font-family: human_sans_400;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #14283c;
  width: 340px;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${AngleDown});
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`;
