import React from "react";
import { ArticlesContainer } from "./style";
import { KeepingSafeImage } from "../../assests";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

const LegalPages = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ArticlesContainer>
      <div
        style={{
          backgroundImage: `url(${KeepingSafeImage})`,
          height: "auto",
          backgroundSize: "cover",
          backgroundPosition: "center 0",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
        className="page-not-found-container"
      >
        <div className="inner-container">
          <div className="title">Legal Notice</div>
        </div>
      </div>
      <div className="articles-container">
        <h2>WEPROTECT TASK FORCE Legal Notice</h2>
        <h3>Key Updates</h3>
        <p>
          Respect for your privacy is rooted in our DNA. From the inception of
          WEPROTECT TASK FORCE, we have built our Services with a set of strong
          privacy principles. In our updated Terms and Privacy Policy, you will
          find:• Our updated Terms and Privacy Policy are easier to understand
          and reflect new WEPROTECT TASK FORCE app and web features.
        </p>
        <ul>
          <li>
            Our Privacy Policy explains how we work together toimprove our
            services and offers.
          </li>
          <li>
            We have built in privacy features, end-to-end encryptionbout and
            other security features in WEPROTECT TASK FORCE. We do not store
            your information.
          </li>
          <li>
            No third party banner ads. We still don’t allow banners
            fromindependent third party advertisements on WEPROTECT TASK FORCE.
          </li>
          <li>
            New ways to use WEPROTECT TASK FORCE. We will exploreways businesses
            and you may need WEPROTECT TASK FORCE.
          </li>
        </ul>
        <h3>WEPROTECT TASK FORCE Terms of Use</h3>
        <span className="date">Last modification: Feb 1st, 2025</span>
        <p style={{ marginTop: "10px" }}>
          <strong>WEPROTECT TASK FORCE SAS.</strong> (“WEPROTECT TASK FORCE”,
          “we” or“our”) provides human protection, geolocation prevention and
          other services worldwide. Please read our Terms of Service for updates
          on your use of WEPROTECT TASK FORCE. You agree to our Terms of Service
          (“Terms”) by installing, viewing or using our applications, services,
          features, software or websites (collectively, the “Services”)LACK OF
          ACCESS TO EMERGENCY SERVICES: There are important differences between
          WEPROTECT TASK FORCE and your mobile, landline and SMS services. Our
          Services do not provide access to emergency services, including
          police, fire, or hospitals, or any other connection to emergency call
          centers. You should ensure that you can contact the relevant emergency
          services via a mobile or landline telephone or other service. <br />
          IF YOU USE WEPROTECT TASK FORCE IN THE UNITED STATES OR CANADA, YOU
          AND WEPROTECT TASK FORCE AGREE TO RESOLVE ALL DISPUTES THROUGH BINDING
          INDIVIDUAL ARBITRATION, WHICH MEANS YOU WAIVE ANY RIGHT TO BRING SUCH
          DISPUTES BEFORE A JUDGE OR JURY AND YOUR RIGHT TO PARTICIPATE IN CLASS
          ACTIONS, CLASS ARBITRATIONS OR REPRESENTATIVE ACTIONS.
        </p>
        <h3>About our services</h3>
        <p>
          <strong>Registration.</strong> You must register for our Services with
          accurate information, provide your current mobile phone number and, if
          it changes, update it using our in-app number change functionality.
          You agree to receive text messages and phone calls (from us or our
          third-party providers) providing you with the codes necessary to
          register for our Services. <br />
          <strong>Age.</strong> You must be at least 13 years old to use our
          Services (or the minimum age required in your country to use our
          Services without parental consent). In addition to the minimum age
          required to use our Services under applicable law, if you are too
          young to be able to accept our Terms in your country, a parent or
          guardian must accept these Terms on your behalf.Devices and software.
          You must have certain devices, software and data connections in order
          to use our Services, which we cannot provide to you without them.
          Throughout the term of use of our Services, you agree to download and
          install updates to our Services, including automatically. <br />
          <strong>Fees and taxes.</strong> You are responsible for all charges
          for your carrier’s data service and other taxes and fees associated
          with your use of our Services. We may charge you for our Services,
          including applicable taxes. We may refuse or cancel orders. We do not
          provide refunds for our Services except as required by law.
        </p>
        <h3>Privacy policy and user data</h3>
        <p>
          <span>Your privacy is important to WEPROTECT TASK FORCE.</span> The
          WEPROTECT TASK FORCE Privacy Policy describes our information
          (including messaging) practices, including the types of information we
          receive and collect from you and how we use and disclose that
          information. You agree to our data practices, including the
          collection, use, processing, and sharing of your information as
          described in our Privacy Policy, and the transfer and processing of
          your information in the United States and other countries. other
          countries in which we have or use facilities, service providers or
          partners, regardless of where you use our Services. You acknowledge
          that the laws, regulations and standards of the country in which your
          information is stored or processed may be different from those of your
          country.
        </p>
        <h3>Acceptable use of our services</h3>
        <p>
          <strong>Our Terms and Policies.</strong> You must use our Services in
          accordance with our posted Terms and policies. If we delete your
          account due to a violation of our Terms, you will not have another one
          without our permission.Legal and acceptable use. You must access and
          use our Services only for legal, authorized and acceptable purposes.
          You will not use (or assist others to use) our Services in a way that:
        </p>
        <ul>
          <li>
            violates, misappropriates or infringes the rights of WEPROTECT TASK
            FORCE, people who use our Services or others , including privacy,
            publicity, intellectual property or other proprietary rights;
          </li>
          <li>
            is unlawful, obscene, defamatory, threatening, intimidating,
            hateful, racially or ethnically offensive in nature, is construed as
            harassment, or incites or encourages unlawful or improper behavior
            for other reasons, including the promotion of violent crime ;
          </li>
          <li>
            {" "}
            involves the publication of lies, false statements or misleading
            assertions;
          </li>
          <li>impersonates anyone;</li>
          <li>
            involves the sending of illegal or objectionable communications such
            as mass messaging, automated messaging, automated dialing and other
            similar practices;{" "}
          </li>
          <li>
            or involves any non-personal use of our Services, unless otherwise
            authorized by us.
          </li>
        </ul>
        <p>
          <strong>
            Harm to WEPROTECT TASK FORCE or to people who use our services.
          </strong>{" "}
          You may not view, use, copy, adapt, modify, distribute, license,
          sublicense, transfer, display, perform our Services, create derivative
          works from them, or exploit them in any other unauthorized manner. or
          which burdens, hinders or harms us, us or our Services, our systems,
          people who use our Services or others (or assist others to do so), and
          shall not under any circumstances, directly or by automated means:
        </p>
        <ul>
          <li>
            reverse engineer, modify, decompile, create derivative works of, or
            extract code from our Services;
          </li>
          <li>
            send, store or transmit viruses or other harmful computer code
            through or on our Services;
          </li>
          <li>
            obtain or attempt to obtain unauthorized access to our Services or
            systems;
          </li>
          <li>
            interfere with or disrupt the integrity or delivery of our Services;
          </li>
          <li>
            collect contact details or information about individuals who use our
            Services in an inadmissible or unauthorized manner;
          </li>
          <li>sell, resell, rent or charge for our Services;</li>
          <li>
            or distribute our Services or make them available on a network that
            allows them to be used on multiple devices at the same time.Protect
            your account. You are responsible for protecting your device and
            your WEPROTECT TASK FORCE account and must notify us promptly of any
            unauthorized use of your account or our Services or any breach of
            security.
          </li>
        </ul>
        <h3>Third party services</h3>
        <p>
          Our Services may allow you to view, use or interact with third-party
          websites, applications, content and other products and services.
          Please note that when you use third-party services, their terms and
          privacy policies govern your use of those services.
        </p>
        <h3>Licenses</h3>
        <p>
          <strong>Your rights.</strong> WEPROTECT TASK FORCE does not claim any
          ownership rights over the information you send to fill your WEPROTECT
          TASK FORCE account or through our Services. You must have the
          necessary rightson the information you send to fill your WEPROTECT
          TASK FORCE account or through our Services and the authorization
          required to grant the rights and licenses mentioned in our
          Terms.Rights of WEPROTECT TASK FORCE. We own all copyrights and
          trademarks, domains, logos, trade dress, trade secrets, patents and
          other intellectual property rights associated with our Services. You
          may not use our copyrights and rights relating to trademarks, domains,
          logos, trade dress, patents and other intellectual property rights
          unless you have our express permission and do not is in compliance
          with our rules of use. <br />
          <strong>License that you grant to WEPROTECT TASK FORCE.</strong> In
          order for us to operate and provide our Services, you grant WEPROTECT
          TASK FORCE a worldwide, non-exclusive, royalty-free, sublicensable,
          transferable license to use, reproduce, distribute, display and
          exploit the information (including content) that you upload, submit,
          store, send or receive on or through our Services, as well as to
          create derivative works thereof. The rights you grant under this
          license are for the limited purposes of operating and providing the
          Services (listed in our Privacy Policy).
          <br />
          <strong>License that WEPROTECT TASK FORCE grants to you.</strong> We
          grant you a limited, revocable, non-exclusive, non-sublicensable,
          non-transferable license to use our Services, subject to and in
          accordance with our Terms. This license is for the sole purpose of
          allowing you to use our Services as permitted by our Terms. No
          licenses or rights are granted to you by implication or otherwise,
          except for the licenses and rights expressly granted to you.
        </p>
        <h3>Liability disclaimers</h3>
        <p>
          YOU USE OUR SERVICES AT YOUR OWN RISK AND SUBJECT TO THE FOLLOWING
          EXCLUSIONS OF LIABILITY. WE PROVIDE OUR SERVICES “AS IS” WITHOUT ANY
          EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, INFRINGEMENT AND FREE OF COMPUTER VIRUS OR OTHER HARMFUL CODE.
          WE DO NOT WARRANT THATTHE INFORMATION PROVIDED BY US IS ACCURATE,
          COMPLETE OR USEFUL, THAT OUR SERVICES WILL BE OPERATIONAL, ERROR-FREE,
          SECURE OR SAFE, OR THAT OUR SERVICES WILL OPERATE WITHOUT
          INTERRUPTIONS, DELAYS OR IMPERFECTIONS. WE DO NOT CONTROL THE WAY OR
          FREQUENCY OF USE OF OUR SERVICES AND ARE NOT RESPONSIBLE FOR THEM. THE
          SAME IS FOR THE FUNCTIONALITIES, SERVICES AND INTERFACES PROVIDED BY
          OUR SERVICES. <br />
          WE ARE NOT OBLIGED TO MONITOR THE ACTIONS OR INFORMATION (INCLUDING
          THE CONTENT) OF INDIVIDUALS WHO USE OUR SERVICES OR OTHER THIRD
          PARTIES AND ARE NOT RESPONSIBLE FOR THEM. YOU HOLD WEPROTECT TASK
          FORCE AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR RESPECTIVE
          DIRECTORS, OFFICERS, EMPLOYEES, PARTNERS, AND REPRESENTATIVES
          (COLLECTIVELY, THE “WEPROTECT TASK FORCE PARTIES”) FROM ANY LIABILITY
          FOR ANY CLAIMS, COMPLAINTS, GROUNDS FOR ACTION, DISPUTE OR LITIGATION
          (COLLECTIVELY “CLAIM”) AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF
          OR RELATING IN ANY WAY TO ANY CLAIM YOU MAKE AGAINST ANY THIRD PARTY.
          <br />
          YOU WAIVE ANY RIGHTS YOU MAY HAVE UNDER THE CIVIL CODE OF THE FRENCH
          STATE, OR ANY OTHER SIMILAR APPLICABLE LEGISLATION OR LAW OF ANY OTHER
          JURISDICTION, STATING THE FOLLOWING: A GENERAL RELEASE DOES NOT APPLY
          TO CLAIMS OF WHICH THE CREDITOR IS NOT AWARE OR WHICH HE DOES NOT
          SUSPECT EXISTS IN HIS FAVOR AT THE TIME OF EXECUTION OF THE RELEASE,
          AND WHICH, IF HE HAD BEEN KNOWN OF IT, WOULD HAVE HAD A MATERIAL
          IMPACT ON HIS SETTLEMENT WITH THE DEBTOR.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          THE WEPROTECT TASK FORCE PARTIES SHALL NOT BE LIABLE FOR LOSS OF
          PROFITS OR ANY CONSEQUENTIAL, PUNITIVE, SPECIAL, CONSEQUENTIAL, OR
          INCIDENTAL DAMAGES, WHETHER ARISING OUT OF OR RELATED TO OUR TERMS,
          FACT OR OUR SERVICES. ‘SOME OTHER WAY, EVEN EVEN THEPARTIES OF
          WEPROTECT TASK FORCE WOULD HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. THE TOTAL AGGREGATE OF OUR LIABILITY TO YOU ARISING OUT
          OF, OR IN ANY OTHER WAY CONNECTED WITH, OUR TERMS, ACTION OR OUR
          SERVICES SHALL NOT EXCEED THE AMOUNT PAID BY YOU DURING THE PREVIOUS
          TWELVE MONTHS OR ONE HUNDRED DOLLARS (100 EUROS), WHICHEVER IS HIGHER.
          <br />
          THE FOREGOING EXCLUSION OF CERTAIN DAMAGES AND LIMITATION OF LIABILITY
          SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. THE
          LAWS OF CERTAIN STATES OR JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR
          LIMITATION OF CERTAIN DAMAGES; ACCORDINGLY, SOME OR ALL OF THE ABOVE
          EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. NOTWITHSTANDING
          ANYTHING TO THE CONTRARY IN OUR TERMS, IN SUCH CASES, THE LIABILITY OF
          THE WEPROTECT TASK FORCE PARTIES SHALL BE LIMITED TO THE FULLEST
          EXTENT PERMITTED BY APPLICABLE LAW.
        </p>
        <h3>Compensation</h3>
        <p>
          You agree to defend, indemnify and hold harmless the WEPROTECT TASK
          FORCE Parties from liabilities, damages, losses and expenses of any
          kind (including reasonable legal fees and costs) relating to, relating
          to or in any way related to the following: (a) your use of or viewing
          of our Services, including information provided through them; (b) a
          breach or alleged breach of our Terms; or (c) any misrepresentation by
          you. You will cooperate fully, at our request, in the defense or
          resolution of a Claim.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          Court of competent jurisdiction. If you use WEPROTECT TASK FORCE you
          agree to resolve any Claim you bring to us relating in any way to or
          arising out of our Terms or Services (each a “Dispute” and
          collectively “Disputes”) exclusively in court. of commerce of PARIS,
          andyou agree to submit to the personal jurisdiction of such courts for
          the purpose of resolving such Disputes.Applicable law. The laws of the
          State of FRANCE govern our Terms, as well as any Dispute brought
          before a court or subject to arbitration, which may arise between
          WEPROTECT TASK FORCE and you, without regard to the provisions on
          conflicts of laws.
        </p>
        <h3>Availability and termination of our services</h3>
        <p>
          <strong>Availability of our services.</strong> Our Services may be
          interrupted, in particular in the event of maintenance, repair, update
          or network or equipment failure. We may discontinue some or all of our
          Services, including certain features, and support for certain devices
          and platforms, at any time. Events beyond our control may interfere
          with our Services, such as natural disasters and other force majeure
          events. <br />
          <strong>Termination.</strong> We may modify, suspend or terminate your
          use or viewing of our Services at any time and for any reason, such as
          if you violate the letter or spirit of our Terms or create harm or
          risk of legal action for us, people who use our Services or others.
          The following provisions will survive termination of your relationship
          with WEPROTECT TASK FORCE: “Licenses”, “Disclaimers”, “Limitation of
          Liability”, “Indemnification”, “Dispute Resolution”, “Availability and
          Termination of our Services “Other” and “Special Arbitration Provision
          for US and Canadian Users”.
        </p>
        <h3>Other</h3>
        <ul>
          <li>
            Unless otherwise provided by mutual agreement between you and us,
            our Terms constitute the entire agreement between you and us
            relating to WEPROTECTTASK FORCE and our Services and supersede any
            previous agreements.
          </li>
          <li>
            We may ask you to agree to additional terms for certainof our
            Services in the future, which will control in the event of any
            conflict between our Termsand these additional terms.
          </li>
          <li>
            Our Services are not intended for distribution or use in any country
            where such distribution or use would violate local law or subject us
            toregulations of another country. We reserve the right to limit our
            Servicesto some countries.
          </li>
          <li>
            We will comply with all export control and trade laws.trade
            sanctions (“Export Laws”) in effect. You must not, under any
            circumstances, directly or indirectly, export, re-export, provide or
            otherwise transfer our Services: (a) to any individual, entity or
            country prohibited by the Export Laws; (b) to anyone on the United
            States government or other country’s lists of unauthorized parties;
            or (c) for any use prohibited by the Export Laws, including for
            nuclear, chemical or biological weapons, or for applications
            involving missile technologies, without the required government
            authorizations. You agree not to use or download our Services if you
            are located in a restricted country, if you are currently on a list
            of unauthorized parties in the United States or another country or
            for any purpose prohibited by Export Laws, and you will not in any
            way mask your location using an IP proxy server or other methods.
          </li>
          <li>
            The original version of our Conditions was written in French. Any
            translations are provided for convenience only. In the event of a
            conflict between the translation of our Terms and the original
            French version, the original version prevails.
          </li>
          <li>
            Any change to or waiver of our Terms must be subject to our express
            consent.
          </li>
          <li>
            We may change or update these Terms. We will provide notice to you
            of any changes to our Terms, if any, and update the last modified
            date at the top of our Terms. Your continued use of our Services
            confirms your acceptance of our Terms, as amended. If you do not
            agree to our Terms, as amended, you must stop using our Services.
            Please feel free to review our Terms from time to time.
          </li>
          <li>
            We may freely assign all of our rights and obligations under our
            Terms to any of our affiliates or in connection with a merger,
            acquisition, restructuring or sale of assets, or by application of a
            law or otherwise, and we may transfer our information to an
            affiliate, successor entity or new owner.
          </li>
          <li>
            You will not transfer your rights or obligations under our Terms to
            any third party without our prior written consent.
          </li>
          <li>Nothing in our Terms prevents us from complying with the law.</li>
          <li>
            Except as provided herein, our Terms do not grant any right
            tobeneficiary to third parties.
          </li>
          <li>
            Any failure on our part to enforce our Conditions shall not be
            consideredas a waiver.
          </li>
          <li>
            If any provision of these Terms is held to be illegal, void or
            notenforceable for any reason, this provision shall be deemed
            severable from our Conditions and will not affect the validity and
            enforceability of any other provisions.
          </li>
          <li>
            We reserve all rights not expressly granted to you. Incertain
            jurisdictions, you may have legal rights as a consumer and our Terms
            are not intended to limit such legal rights which may not be
            contractually waivable.
          </li>
          <li>
            We welcome your comments and suggestions regarding WEPROTECT TASK
            FORCE and our Services. However, please note that we can use them
            without any obligation to pay (just as you have no obligation to
            communicate them to us).Access to WEPROTECT TASK FORCE conditions in
            other languages. To access our Terms in certain other languages,
            change the language settings in your WEPROTECT TASK FORCE session.
            If our Terms are not available in the selected language, the English
            version will be displayed by default.
          </li>
        </ul>
      </div>
    </ArticlesContainer>
  );
};

export default LegalPages;
