import React, { useContext, useEffect} from "react";
import { BecomeResponderContainer, HowDoesItWorkContainer } from "./style";
import {
  JobsSection,
  BecomeResponderImageOne,
  BecomeResponderImageTwo,
  BecomeResponderImageThree,
} from "../../assests";
import { useIntl } from "react-intl";
import { MyAppContext } from "../../context/MyAppContext";
import BecomeResponderForm from "../BecomeResponderForm";


const BecomeResponderPage = () => {
  const intl = useIntl();
  const { userLoginGeoLocation } =
    useContext(MyAppContext);

  return (
    <BecomeResponderContainer>
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(5, 109, 255, 0.16), rgba(5, 109, 255, 0.16)), linear-gradient(186.05deg, rgba(70, 86, 149, 0.5) 4.79%, rgba(15, 27, 77, 0.5) 91.43%),url(${JobsSection})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="responder-header-div "
      >
        <div className="inner-container">
          <BecomeResponderForm />
        </div>
      </div>
      <HowDoesItWorkContainer>
        <div
          className="header-title"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "BECOME_RESPONDER.SUB_TITLE" }),
          }}
        />
        <div className="item-flex">
          <div className="item">
            <img src={BecomeResponderImageThree} alt="" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "BECOME_RESPONDER.POINT_ONE",
                }),
              }}
            />
          </div>
          <div className="item">
            <img src={BecomeResponderImageTwo} alt="" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "BECOME_RESPONDER.POINT_TWO",
                }),
              }}
            />
          </div>
          <div className="item">
            <img src={BecomeResponderImageOne} alt="" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    userLoginGeoLocation?.currency === "USD"
                      ? "BECOME_RESPONDER.POINT_THREE_USD"
                      : "BECOME_RESPONDER.POINT_THREE",
                }),
              }}
            />
          </div>
        </div>
      </HowDoesItWorkContainer>
    </BecomeResponderContainer>
  );
};
export default BecomeResponderPage;
