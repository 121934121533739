import styled from "styled-components";
import PhoneInput from "react-phone-number-input";

export const PresSubscribeFormContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px 24px;

  .header, .sub-header {
  font-family: "roboto_condensed_700";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #14283c;
  }
.sub-header {
  font-size: 16px;
  font-family: "roboto_condensed_400";
}

  .d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 26px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: start;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: start;
    }
  }
  .phone-number-div {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    @media (max-width: 768px) {
      width: -webkit-fill-available;
    }
    @media (max-width: 1000px) {
      width: -webkit-fill-available;
    }
    label {
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 11px;
      line-height: 12px;
      color: #14283c;
    }
  }
  .d-flex-column {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 24px;
    flex: 1 1 0;
    label {
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 11px;
      line-height: 12px;
      color: #5573a0;
    }
    @media (max-width: 768px) {
      width: -webkit-fill-available;
    }
    @media (max-width: 1000px) {
      width: -webkit-fill-available;
    }
  }
  .subscription {
    background-color: #ffa53326;
    border-radius: 30px;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    .subscription-info {
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      color: #14283c;
    }
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      gap: 10px;
    }
    @media (max-width: 1000px) {
      gap: 10px;
    }
    .cancel-btn {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.004em;

      @media (max-width: 768px) {
        width: 100px;
        padding: unset;
      }
      @media (max-width: 1000px) {
        width: 100px;
        padding: unset;
      }
    }
    .submit-btn {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.004em;

      @media (max-width: 768px) {
        width: 150px;
        padding: unset;
      }
      @media (max-width: 1000px) {
        width: 150px;
        padding: unset;
      }
    }
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  border-radius: 12px 0 0 12px;
  width: -webkit-fill-available;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  &.PhoneInput {
    border-radius: 12px !important;
  }
  .PhoneInputCountry {
    border-right: 1px solid #91afd770;
    padding: 12px;
  }
  input {
    border-radius: 0 12px 12px 0 !important;
    border: solid 1px #9b9b9b;
    font-family: human_sans_400;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #14283c;
    outline: unset !important;
    border: unset !important;
    padding: 10px 12px;
    ::placeholder {
      line-height: 20px;
      color: #91afd7;
      font-family: human_sans_400;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const GenderListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .gender-div {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #c8dcfa;
    mix-blend-mode: normal;
    // opacity: 0.5;
    border-radius: 12px;
    font-family: human_sans_400;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // color: #056dff;
    cursor: pointer;
    padding: 7.5px;
    b,
    span {
      margin-top: 5px;
      margin-left: 5px;
    }
  }
`;
