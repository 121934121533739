import React from "react";
import { ArticlesContainer } from "./style";
import { KeepingSafeImage } from "../../assests";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ArticlesContainer>
      <div
        style={{
          backgroundImage: `url(${KeepingSafeImage})`,
          height: "auto",
          backgroundSize: "cover",
          backgroundPosition: "center 0",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
        className="page-not-found-container"
      >
        <div className="inner-container">
          <div className="title">Privacy Policy</div>
        </div>
      </div>
      <div className="articles-container">
        <h2>WEPROTECT TASK FORCE Privacy Policy</h2>
        <span className="date">Last modification: Feb 1st, 2025</span>
        <p>
          <strong>Respect for your privacy is rooted in our DNA.</strong> From
          the inception of WEPROTECT TASK FORCE, we have built our Services with
          a set of strong privacy principles.WEPROTECT TASK FORCE provides,
          among other things, protection, prevention, intervention and
          customized services worldwide. Our Privacy Policy helps us explain our
          information (including messaging) practices.
          <br /> For example, we present the information we collect and the
          impact it has on you. We also explain the measures we take to protect
          the privacy of your personal information, such as designing WEPROTECT
          TASK FORCE so that messages sent are not stored and giving you control
          over who you communicate with on our Services.Unless otherwise
          specified, when we speak of “WEPROTECT TASK FORCE”, “us”, “our” or
          “we”, we refer to WEPROTECT TASK FORCE SAS. This privacy policy
          (“Privacy Policy”) applies to all of our applications, services,
          features, software, and websites (together referred to as
          “Services”).Please also review the WEPROTECT TASK FORCE Terms of
          Service (“Terms of Service”) which outlines the terms governing your
          use of our Services.
        </p>
        <h3>Information We Collect</h3>
        <p>
          WEPROTECT TASK FORCE receives or collects information when we operate
          and provide our Services, including when you install, access or use
          them.
          <br />
          <strong>Information you provide:</strong>
        </p>
        <ul>
          <li>
            Information relating to your account – You provide your mobile phone
            number to create your WEPROTECT TASK FORCE account. You can also add
            other information about your account, such as a profile name,
            profile picture.
          </li>
          <li>
            Your messages – We do not retain your messages during the provision
            of our Services in normal times.
          </li>
          <li>
            User Support – You may provide us with information about your use of
            our Services, including copies of your messages, and how we may
            contact you to provide you with appropriate user support. For
            example, you can send us an email with information about app
            performance or other issues.
          </li>
        </ul>
        <h3>Information Collected Automatically</h3>
        <p>
          We collect certain information automatically during the provision of
          our Services.
        </p>
        <ul>
          <li>
            <strong>Usage and Information about log files.</strong> We collect
            service, diagnostic and performance information. This includes
            information about your activity (such as how you use our Services,
            how you interact with others using our Services, etc.) and log
            files, as well as diagnostic, crash logs and reports , website and
            performance.
          </li>
          <li>
            <strong>Device information and connection data.</strong>We collect
            informationdevice-specific when you install, access or use our
            Services. This includes information such as device model, operating
            system information, browser information, IP address, mobile network
            information including phone number, and user IDs. the device. We
            collect device location information if you use our location
            features, such as when you choose to share your location with your
            contacts, see nearby locations or those others have shared with you,
            and for the purposes of diagnosis and troubleshooting, such as when
            you have issues with your app’s location feature.
          </li>
          <li>
            <strong>Cookies.</strong> We use cookies to operate and provide our
            Services, including to provide our Services on the web, to improve
            your experience, to understand how our Services are used, and to
            personalize our Services. For example, we use cookies to provide
            WEPROTECT TASK FORCE for web and desktop and other website services.
            We may also use cookies to understand which Q&A pages are most
            popular and contain relevant information about our Services.
            Additionally, we may use cookies to remember your choices, such as
            your language preferences, as well as to personalize our Services.
          </li>
        </ul>
        <h3>Third Party Information</h3>
        <ul>
          <li>
            <strong>Information Others Share About You.</strong> We receive
            information from others which may contain information about
            you.regarding.
          </li>
          <li>
            <strong>Third Party Suppliers.</strong> We work with third-party
            vendors who help us to operate, provide, improve, understand,
            customize, support and market our Services. For example, we work
            with companies to distribute our applications, provide our
            infrastructure, broadcasts and other systems, provide map and
            location information, process payments, understand how people use
            our Services and market them. These providers may provide us with
            information about you in certain circumstances. For example, app
            stores may provide us with reports to help us diagnose and resolve
            certain issues.
          </li>
          <li>
            <strong>Third Party Services.</strong> We allow you to use our
            Services in connection with third-party services.
          </li>
        </ul>
        <h3>Data usage</h3>
        <p>
          We use all the information we have to help us operate, provide,
          improve, understand, customize, support and market our Services.
        </p>
        <ul>
          <li>
            <strong>Our services.</strong> We operate and provide our Services,
            which includes providing user support and improving, fixing and
            personalizing our Services. We understand how people use our
            Services, and we analyze and use information we have to evaluate and
            improve our Services, research, develop and test new services and
            features, and perform troubleshooting activities. We also use your
            information to respond to you when you contact us. We use cookies to
            operate, provide, improve, understand and personalize our Services.
          </li>
          <li>
            <strong>Protection and security.</strong> We verify accounts and
            activities, and ensure security across and outside of our services.
            We can, for example, investigate suspicious activity or violations
            of our Terms of Service and ensure that our Services are used
            lawfully.
          </li>
          <li>
            We communicate with you about our Services and features, and notify
            you of our terms and policies and important updates. We may send you
            advertisements for our Services.
          </li>
          <li>
            <strong>No Third Party Banner Ads.</strong> We do not allow third
            party banner ads on WEPROTECT TASK FORCE. We do not intend to
            incorporate them, but if we ever do, we will update this policy.
          </li>
        </ul>
        <h3>Information shared by you and us</h3>
        <p>
          You provide your information when you use and communicate through our
          Services, and we share your information to help us operate, provide,
          improve, understand, personalize, assist, and market our Services.
        </p>
        <ul>
          <li>
            <strong>Account Information.</strong> Your phone number, name, and
            profile picture may be viewable by anyone who manages our Services,
            although you can configure your Services settings to manage certain
            information.accessible to other people.
          </li>
          <li>
            <strong>Third Party Suppliers.</strong> We work with third-party
            vendors who help us to operate, provide, improve, understand,
            customize, support and market our Services. When we pass information
            to third-party providers, we ask them to use your information in
            accordance with our instructions and conditions or with your express
            permission.
          </li>
          <li>
            <strong>Third Party Services.</strong> When you use third-party
            services integrated into our Services, they may receive information
            about what you communicate to them.
          </li>
        </ul>
        <h3>Allocation, Change of Control and Transfer</h3>
        <p>
          You provide your information when you use and communicate through our
          Services, and we share your information to help us operate, provide,
          improve, understand, personalize, assist, and market our Services.
        </p>
        <h3>Law and protection</h3>
        <p>
          We may collect, use, retain and disclose your information if we have a
          good faith belief that it is reasonably necessary to:
        </p>
        <ul>
          <li>
            respond to legal process or governmental request under applicable
            law or applicable regulations;
          </li>
          <li>
            enforce our Terms of Service and any other applicable terms or
            policies, including reviews of possible violations;
          </li>
          <li>
            detect, investigate, prevent and address fraud and other illegal
            activity or technical or security issues;
          </li>
          <li>
            protect the rights, property, and safety of people, WEPROTECT TASK
            FORCE, or other entities.
          </li>
        </ul>
        <h3>Our international activities</h3>
        <p>
          You agree to our information practices, including the collection, use,
          processing, and sharing of your information as described in this
          Privacy Policy, and the transfer and processing of your information in
          France, United States and in other countries in which we have or use
          facilities, service providers or partners, regardless of where you use
          our Services. You acknowledge that the laws, regulations and standards
          of the country in which your information is stored or processed may be
          different from those of your country.
        </p>
        <h3>Updates to our policy</h3>
        <p>
          If you have any questions regarding our Privacy Policy, please contact
          us:
          <br /> WeProtect Task Force.
          <br /> 174 Boulevard Saint-Germain
          <br /> 75006 Paris
          <br />
          France
        </p>
        <h3>Cookies</h3>
        <p>
          If you have any questions regarding our Privacy Policy, please contact
          us:
          <br />
          WeProtect Task Force.
          <br />
          174 Boulevard Saint-Germain
          <br />
          75006 Paris
          <br />
          France
        </p>
        <h3>About Cookies</h3>
        <p>
          A cookie is a small text file that a site you have visited asks your
          search engine to store on your computer or mobile phone.How we use
          cookies. We use cookies to understand, secure, manage and provide our
          Services. For example, we use cookies to:
        </p>
        <ul>
          <li>
            To provide you with WEPROTECT TASK FORCE for Web and Desktop and
            other Web Services, improve your experiences, understand how our
            Services are usedand personalize our Services;
          </li>
          <li>
            Understand which of our Q&As are the most popular and to provide you
            with relevant content relating to our Services;
          </li>
          <li>
            Remember your choices, such as a language preference, or even to
            personalize our Services according to your preferences; and
          </li>
          <li>
            Rank our Q&A on our site in order of popularity, understand mobile
            users versus computer users when they are on our Web Services, or
            understand the popularity and effectiveness of some of our web
            pages.
          </li>
        </ul>
        <h3>How to control cookies</h3>
        <p>
          You can follow the instructions provided by your search engine or
          device (usually located under “Settings” or “Preferences”) to change
          your cookie settings. Please note that if you disable cookies in your
          search engine or device, some of our Services may not function
          properly.
        </p>
      </div>
    </ArticlesContainer>
  );
};

export default PrivacyPolicy;
