import React, { useContext, useState, useRef } from "react";
import {
  NavBarContainer,
  NavContainer,
  NavBarForResponsiveContainer,
} from "./style";
import { NavLink } from "react-router-dom";
import { WPTFLogo } from "../../assests";
import MyButton from "../../components/Elements/MyButton/index";
import { useLang, setLanguage } from "./../../i18n";
import { FormattedMessage, useIntl } from "react-intl";
import { MyAppContext } from "../../context/MyAppContext";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { RiRecordCircleFill, RiCheckboxBlankCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useMediaQuery } from "react-responsive";
import { HiMenu } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { FranceFlag, EnglishFlag } from "../../assests";

const NavBar = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });
  const langDropdownRef = useRef();
  const navBarOpenRef = useRef();
  const lang = useLang();
  const navigate = useNavigate();
  const [colorChange, setColorchange] = useState(false);
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  useOutsideClick(langDropdownRef, () => {
    setIsLangDropdownOpen(false);
  });

  useOutsideClick(navBarOpenRef, () => {
    setIsNavBarOpen(false);
  });

  return (
    <NavBarContainer
      colorChange={colorChange}
      isMobile={isMobile || isTablet}
      isNavBarOpen={isNavBarOpen}
    >
      {isNavBarOpen && (isMobile || isTablet) ? (
        <NavBarForResponsiveContainer
          ref={navBarOpenRef}
          isFrenchLangActive={lang === "fr" ? true : false}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <img
              src={WPTFLogo}
              alt=""
              style={{
                width: "127px",
                height: "31px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            />
            <AiOutlineClose
              style={{
                color: "#ffffff",
                width: "56px",
                height: "40px",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsNavBarOpen(!isNavBarOpen);
              }}
            />
          </div>
          <div className="navbar-right">
            <NavLink
              to="about-us"
              onClick={() => {
                setIsNavBarOpen(false);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <FormattedMessage id="NAVBAR.ABOUT_US" />
            </NavLink>
            <NavLink
              to="become-responder"
              onClick={() => {
                setIsNavBarOpen(false);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <FormattedMessage id="NAVBAR.BECOME_RESPONDER" />
            </NavLink>
            <div className="nav-lang-dropdown" ref={langDropdownRef}>
              <div className="nav-lang-country-name">
                {lang === "en" ? (
                  <>
                    <img src={EnglishFlag} alt="english_flag" width={20} /> {' '}
                    {intl.formatMessage({
                      id: "NAVBAR.EN",
                    })}
                  </>
                ) : (
                  <>
                    <img src={FranceFlag} alt="france_flag" width={20} /> {' '}
                    {intl.formatMessage({
                      id: "NAVBAR.FR",
                    })}
                  </>
                )}
              </div>
              {isLangDropdownOpen ? (
                <FiChevronUp
                  color="white"
                  size={16}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsLangDropdownOpen(!isLangDropdownOpen);
                  }}
                />
              ) : (
                <FiChevronDown
                  color="white"
                  size={16}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsLangDropdownOpen(!isLangDropdownOpen);
                  }}
                />
              )}
              {isLangDropdownOpen && (
                <div className="dropdown-list">
                  <div
                    className="menu-list"
                    onClick={() => {
                      setLanguage("en");
                      setIsLangDropdownOpen(!isLangDropdownOpen);
                      setIsNavBarOpen(false);
                    }}
                    style={{
                      padding: "15px",
                    }}
                  >
                    <div className="menu-list-text">
                    <img src={EnglishFlag} alt="english_flag" width={20} /> {' '}
                      {intl.formatMessage({
                        id: "NAVBAR.EN",
                      })}
                    </div>
                    {lang === "en" ? (
                      <RiRecordCircleFill
                        style={{
                          fontSize: "20px",
                          color: "#056DFF",
                        }}
                      />
                    ) : (
                      <RiCheckboxBlankCircleLine
                        style={{
                          fontSize: "20px",
                          color: "#056DFF",
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="menu-list"
                    onClick={() => {
                      setLanguage("fr");
                      setIsLangDropdownOpen(!isLangDropdownOpen);
                      setIsNavBarOpen(false);
                    }}
                    style={{
                      padding: "10px 15px 15px 15px",
                    }}
                  >
                    <div className="menu-list-text">
                      <img src={FranceFlag} alt="france_flag" width={20} /> {' '}
                      {intl.formatMessage({
                        id: "NAVBAR.FR",
                      })}
                    </div>
                    {lang === "fr" ? (
                      <RiRecordCircleFill
                        style={{
                          fontSize: "20px",
                          color: "#056DFF",
                        }}
                      />
                    ) : (
                      <RiCheckboxBlankCircleLine
                        style={{
                          fontSize: "20px",
                          color: "#056DFF",
                          marginBottom: "2px",
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </NavBarForResponsiveContainer>
      ) : (
        <NavContainer isFrenchLangActive={lang === "fr" ? true : false}>
          <div>
            <img
              src={WPTFLogo}
              alt=""
              style={{
                width: "127px",
                height: "31px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            />
          </div>
          <div>
            {isMobile || isTablet ? (
              <>
                <div
                  style={{
                    padding: "5px",
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "0.375rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsNavBarOpen(!isNavBarOpen);
                  }}
                >
                  <HiMenu
                    style={{
                      color: "#ffffff",
                      width: "56px",
                      height: "40px",
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="navbar-right">
                <NavLink to="about-us">
                  <FormattedMessage id="NAVBAR.ABOUT_US" />
                </NavLink>
                <NavLink to="become-responder">
                  <FormattedMessage id="NAVBAR.BECOME_RESPONDER" />
                </NavLink>
                <div className="nav-lang-dropdown" ref={langDropdownRef}>
                  <div className="nav-lang-country-name">
                    {lang === "en" ? (
                      <>
                        🇬🇧&nbsp;
                        {intl.formatMessage({
                          id: "NAVBAR.EN",
                        })}
                      </>
                    ) : (
                      <>
                        🇫🇷&nbsp;
                        {intl.formatMessage({
                          id: "NAVBAR.FR",
                        })}
                      </>
                    )}
                  </div>
                  {isLangDropdownOpen ? (
                    <FiChevronUp
                      color="white"
                      size={16}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsLangDropdownOpen(!isLangDropdownOpen);
                      }}
                    />
                  ) : (
                    <FiChevronDown
                      color="white"
                      size={16}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsLangDropdownOpen(!isLangDropdownOpen);
                      }}
                    />
                  )}
                  {isLangDropdownOpen && (
                    <div className="dropdown-list">
                      <div
                        className="menu-list"
                        onClick={() => {
                          setLanguage("en");
                          setIsLangDropdownOpen(!isLangDropdownOpen);
                        }}
                        style={{
                          padding: "15px",
                        }}
                      >
                        <div className="menu-list-text">
                          🇬🇧&nbsp;{" "}
                          {intl.formatMessage({
                            id: "NAVBAR.EN",
                          })}
                        </div>
                        {lang === "en" ? (
                          <RiRecordCircleFill
                            style={{
                              fontSize: "20px",
                              color: "#056DFF",
                            }}
                          />
                        ) : (
                          <RiCheckboxBlankCircleLine
                            style={{
                              fontSize: "20px",
                              color: "#056DFF",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="menu-list"
                        onClick={() => {
                          setLanguage("fr");
                          setIsLangDropdownOpen(!isLangDropdownOpen);
                        }}
                        style={{
                          padding: "10px 15px 15px 15px",
                        }}
                      >
                        <div className="menu-list-text">
                          🇫🇷&nbsp;{" "}
                          {intl.formatMessage({
                            id: "NAVBAR.FR",
                          })}
                        </div>
                        {lang === "fr" ? (
                          <RiRecordCircleFill
                            style={{
                              fontSize: "20px",
                              color: "#056DFF",
                            }}
                          />
                        ) : (
                          <RiCheckboxBlankCircleLine
                            style={{
                              fontSize: "20px",
                              color: "#056DFF",
                              marginBottom: "2px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </NavContainer>
      )}
    </NavBarContainer>
  );
};

export default NavBar;
